import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col } from '../components/global'
import Image from '../components/image'

const AboutPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <h1>
        About
        <br />
        Veejer Enterprises, Inc.
      </h1>
    </div>
    <div className="container">
      <Row break={false}>
        <Col width="12%">
          <Image file="vince_fischelli.jpg" />
        </Col>
        <Col align="left" width="88%">
          <p>
            The developer, technical writer and overall creator of these electrical-electronics
            training programs is Vince Fischelli, President of Veejer Enterprises Inc.
          </p>
        </Col>
      </Row>
    </div>
    <div className="container container-last">
      <p>
        Vince Fischelli founded Veejer Enterprises in 1985. He is a Technical Training Consultant
        specializing in <strong>vehicle electrical and electronics troubleshooting training</strong>{' '}
        for all segments of the vehicle service industry (autos, trucks and heavy equipment).
      </p>
      <p>
        He is a graduate of seven electronics schools, graduating from his first electronics school
        in 1959 as a U.S Marine attending the U.S. Navy's Electronics "A" School at Great Lakes, IL.
      </p>
      <p>
        His military career spanned 12 years during which he completed several advanced electronics
        schools and worked on the military's most advanced electronics systems of that time.
      </p>
      <p>
        He entered civilian life in late 1967 and began working in the automotive repair industry at
        an automotive electronic service center in Dallas, Texas where his electronics background
        was applied to solving automotive electrical problems that stumped dealership technicians.
      </p>
      <p>
        He later operated his own repair shop from 1975 to 1980 where he began training service
        technicians he hired to perform the high caliber of electrical work he brought in to the
        shop. Vince is a natural born teacher and trainer and was often called on in the military to
        conduct electronic classes. It was a natural thing for him to train the technicians he hired
        to work in his own shop.
      </p>
      <p>
        His training methods were so successful that several of his top techs he trained eventually
        left his company to become lead electrical specialists for other shops of the local
        competition who made his highly trained employee technicians financial packages they
        couldn't refuse. After losing several technicians to other shops he decided to close his
        shop in 1980 and go into the electronics industry.
      </p>
      <p>
        Six months later he was offered a job to become the technical manager of GM's ECM
        remanufacturing facility in Dallas. He set up and ran tthis operation for nearly 5 years and
        also set up GM's digital dash remanufacturing facility in Dallas.
      </p>
      <p>
        In this capacity he noticed an increasing lack of proper electrical-electronics
        troubleshooting training for automotive technicians was hampering their ability to perform
        proper automotive computer control system diagnosis and to correct driveability problems
        without changing a lot of unnecessary parts.
      </p>
      <p>
        Most technicians he noticed where just parts changers. Repair costs were excessive and
        customer comebacks where a major problem for new car dealerships. He was asked to provide
        some training in night classes in the Dallas area for dealership technicians which he did.
      </p>
      <p>
        This led him to decide to start Veejer Enterprises in 1985 to provide the level of
        electrical-electronics troubleshooting training needed in the vehicle service industry. He
        originally trained at the dealership level as a private trainer but later began to move
        throughout the automotive service industry, truck service industry and heavy equipment
        service industry as he became known.
      </p>
      <p>
        He is the author of more than 16 books, numerous magazine technical articles and technical
        papers on vehicle electrical and vehicle electronics troubleshooting.
      </p>
      <p>
        He developed several electrical-electronics troubleshooting workshops. These workshops cover
        vehicle electrical and electronics troubleshooting for journeyman through the advanced
        level.
      </p>
      <p>
        In the past Vince developed the original Automotive and Truck/Heavy Duty Electrical Clinic
        Training Programs for INTERSTATE BATTERY SYSTEM OF AMERICA.
      </p>
      <p>He was the Electronics Editor of the former IMPORT SERVICE MAGAZINE for seven years.</p>
      <p>
        He has written and produced numerous video training tapes on electronics for a number of
        vehicle manufacturers, including CADILLAC MOTOR DIVISION, INTERSTATE BATTERIES and CHAMPION
        SPARK PLUG.
      </p>
      <p>
        He is a featured speaker at industry trade shows and conventions presenting
        electrical-electronics troubleshooting training seminars for all segments of the vehicle
        service industry. Vince can be contacted at the location below.
      </p>
    </div>
  </Layout>
)

export default AboutPage
